
    var ret = {
      defaultLocale: "en-US",
      fallbackLocale: "en-US",
      availableLocales: ["da","de","de-formal","de-informal","en-US","es","fi-FI","fr-FR","nl-NL","pl","pt-BR","ro","ru","sv"],
      bundled: {},
      loadables: {},
    };

  // Bundled locales are directly available in the main bundle.
  
    {
      var suffixes = [".ftl"];
      var contents = [];
    
      contents.push(require("/usr/src/app/src/locales/en-US/common.ftl").default);
    

      contents.push(require("/usr/src/app/src/locales/en-US/framework.ftl").default);
    

      contents.push(require("/usr/src/app/src/locales/en-US/ui.ftl").default);
    
      contents = contents.concat(suffixes.map(function(suffix) { return require("/usr/src/app/src/locales/en-US/admin" + suffix).default; }));
      ret.bundled["en-US"] = contents.join("\n");
    }
  

  // Loadables are in a separate bundle, that can be easily loaded.
  
    ret.loadables["da"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-da", webpackMode: "lazy" */
          "/usr/src/app/src/locales/da/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-da", webpackMode: "lazy" */
          "/usr/src/app/src/locales/da/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-da", webpackMode: "lazy" */
          "/usr/src/app/src/locales/da/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-da", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/da/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["de"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de", webpackMode: "lazy" */
          "/usr/src/app/src/locales/de/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de", webpackMode: "lazy" */
          "/usr/src/app/src/locales/de/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-de", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/de/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["de-formal"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de-formal", webpackMode: "lazy" */
          "/usr/src/app/src/locales/de-formal/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de-formal", webpackMode: "lazy" */
          "/usr/src/app/src/locales/de-formal/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-de-formal", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/de-formal/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["de-informal"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de-informal", webpackMode: "lazy" */
          "/usr/src/app/src/locales/de-informal/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-de-informal", webpackMode: "lazy" */
          "/usr/src/app/src/locales/de-informal/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-de-informal", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/de-informal/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["es"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-es", webpackMode: "lazy" */
          "/usr/src/app/src/locales/es/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-es", webpackMode: "lazy" */
          "/usr/src/app/src/locales/es/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-es", webpackMode: "lazy" */
          "/usr/src/app/src/locales/es/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-es", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/es/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["fi-FI"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fi-FI", webpackMode: "lazy" */
          "/usr/src/app/src/locales/fi-FI/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fi-FI", webpackMode: "lazy" */
          "/usr/src/app/src/locales/fi-FI/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-fi-FI", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/fi-FI/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["fr-FR"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fr-FR", webpackMode: "lazy" */
          "/usr/src/app/src/locales/fr-FR/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fr-FR", webpackMode: "lazy" */
          "/usr/src/app/src/locales/fr-FR/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-fr-FR", webpackMode: "lazy" */
          "/usr/src/app/src/locales/fr-FR/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-fr-FR", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/fr-FR/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["nl-NL"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-nl-NL", webpackMode: "lazy" */
          "/usr/src/app/src/locales/nl-NL/common.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-nl-NL", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/nl-NL/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["pl"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pl", webpackMode: "lazy" */
          "/usr/src/app/src/locales/pl/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pl", webpackMode: "lazy" */
          "/usr/src/app/src/locales/pl/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pl", webpackMode: "lazy" */
          "/usr/src/app/src/locales/pl/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-pl", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/pl/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["pt-BR"] = function() {
      var suffixes = [".ftl"];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pt-BR", webpackMode: "lazy" */
          "/usr/src/app/src/locales/pt-BR/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pt-BR", webpackMode: "lazy" */
          "/usr/src/app/src/locales/pt-BR/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-pt-BR", webpackMode: "lazy" */
          "/usr/src/app/src/locales/pt-BR/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-pt-BR", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/pt-BR/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["ro"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ro", webpackMode: "lazy" */
          "/usr/src/app/src/locales/ro/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ro", webpackMode: "lazy" */
          "/usr/src/app/src/locales/ro/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-ro", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/ro/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["ru"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-ru", webpackMode: "lazy" */
          "/usr/src/app/src/locales/ru/framework.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-ru", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/ru/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  

    ret.loadables["sv"] = function() {
      var suffixes = [];
      var promises = [];
    
      promises.push(
        import(
          /* webpackChunkName: "admin-locale-sv", webpackMode: "lazy" */
          "/usr/src/app/src/locales/sv/common.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-sv", webpackMode: "lazy" */
          "/usr/src/app/src/locales/sv/framework.ftl"
        )
      );
    

      promises.push(
        import(
          /* webpackChunkName: "admin-locale-sv", webpackMode: "lazy" */
          "/usr/src/app/src/locales/sv/ui.ftl"
        )
      );
    
      promises = promises.concat(suffixes.map(function(suffix) {
        return import(
          /* webpackChunkName: "admin-locale-sv", webpackMode: "lazy-once" */
          "/usr/src/app/src/locales/sv/admin" + suffix
        )
      }));
      return Promise.all(promises).then(function(modules) {
        return modules.map(function(m){return m.default}).join("\n");
      });
    };
  
    module.exports = ret;
  