import { Localized } from "@fluent/react/compat";
import React, { FunctionComponent } from "react";
import { Field } from "react-final-form";

import { parseEmptyAsNull, ValidationMessage } from "coral-framework/lib/form";
import { Validator } from "coral-framework/lib/validation";
import { FormField, Label, PasswordField } from "coral-ui/components/v2";

interface Props {
  validate?: Validator;
  name: string;
  disabled: boolean;
}

const ApiTokenField: FunctionComponent<Props> = ({
  name,
  disabled,
  validate,
}) => (
  <FormField>
    <Field
      name={name}
      key={(disabled && "on") || "off"}
      parse={parseEmptyAsNull}
      validate={validate}
    >
      {({ input, meta }) => (
        <>
          <Localized id="configure-auth-apiToken">
            <Label htmlFor={input.name}>API Token</Label>
          </Localized>
          <PasswordField
            {...input}
            id={input.name}
            disabled={disabled || meta.submitting}
            autoComplete="new-password"
            hidePasswordTitle="Show API Token"
            showPasswordTitle="Hide API Token"
            fullWidth
          />
          <ValidationMessage meta={meta} fullWidth />
        </>
      )}
    </Field>
  </FormField>
);

export default ApiTokenField;
