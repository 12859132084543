/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SignInWithPianoContainer_auth = {
    readonly integrations: {
        readonly piano: {
            readonly redirectURL: string;
        };
    };
    readonly " $refType": "SignInWithPianoContainer_auth";
};
export type SignInWithPianoContainer_auth$data = SignInWithPianoContainer_auth;
export type SignInWithPianoContainer_auth$key = {
    readonly " $data"?: SignInWithPianoContainer_auth$data;
    readonly " $fragmentRefs": FragmentRefs<"SignInWithPianoContainer_auth">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInWithPianoContainer_auth",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "AuthIntegrations",
            "kind": "LinkedField",
            "name": "integrations",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PianoAuthIntegration",
                    "kind": "LinkedField",
                    "name": "piano",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "redirectURL",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Auth",
    "abstractKey": null
} as any);
(node as any).hash = '75e563215a1a67ceaac19e71d3f770ac';
export default node;
