import { Localized } from "@fluent/react/compat";
import React, { Component } from "react";
import { graphql } from "react-relay";

import PianoButton from "coral-framework/components/PianoButton";
import { redirectOAuth2 } from "coral-framework/helpers";
import { withFragmentContainer } from "coral-framework/lib/relay";

import { SignInWithPianoContainer_auth as AuthData } from "coral-admin/__generated__/SignInWithPianoContainer_auth.graphql";

interface Props {
  auth: AuthData;
}

class SignInWithPianoContainer extends Component<Props> {
  private handleOnClick = () => {
    redirectOAuth2(this.props.auth.integrations.piano.redirectURL);
  };

  public render() {
    return (
      <Localized id="login-signInWithPiano">
        <PianoButton onClick={this.handleOnClick}>
          Sign in with Piano
        </PianoButton>
      </Localized>
    );
  }
}

const enhanced = withFragmentContainer<Props>({
  auth: graphql`
    fragment SignInWithPianoContainer_auth on Auth {
      integrations {
        piano {
          redirectURL
        }
      }
    }
  `,
})(SignInWithPianoContainer);

export default enhanced;
