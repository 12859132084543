import { Localized } from "@fluent/react/compat";
import React, { FunctionComponent } from "react";
import { graphql } from "react-relay";

import {
  Condition,
  required,
  validateWhen,
} from "coral-framework/lib/validation";

import { FormField, FormFieldHeader, Label } from "coral-ui/components/v2";

import Header from "../../Header";
import HelperText from "../../HelperText";
import HorizontalRule from "../../HorizontalRule";
import OnOffField from "../../OnOffField";
import ApiTokenField from "./ApiTokenField";
import ClientIDField from "./ClientIDField";
import ClientSecretField from "./ClientSecretField";
import ConfigBoxWithToggleField from "./ConfigBoxWithToggleField";
import RedirectField from "./RedirectField";
import RegistrationField from "./RegistrationField";
import SigningSecretField from "./SigningSecretField";
import TargetFilterField from "./TargetFilterField";

import styles from "./PianoConfig.css";

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment PianoConfig_formValues on Auth {
    integrations {
      piano {
        enabled
        allowRegistration
        sandbox
        targetFilter {
          admin
          stream
        }
        clientID
        apiToken
        clientSecret
        signingSecret
      }
    }
  }
`;
interface Props {
  disabled?: boolean;
  callbackURL: string;
}

const isEnabled: Condition = (value, values) =>
  Boolean(values.auth.integrations.piano.enabled);

const PianoConfig: FunctionComponent<Props> = ({ disabled, callbackURL }) => (
  <ConfigBoxWithToggleField
    title={
      <Localized id="configure-auth-piano-loginWith">
        <Header container="h2">Login with Piano</Header>
      </Localized>
    }
    name="auth.integrations.piano.enabled"
    disabled={disabled}
    data-testid="configure-auth-piano"
  >
    {(disabledInside) => (
      <>
        <RedirectField url={callbackURL} />
        <HorizontalRule />
        <FormField>
          <FormFieldHeader>
            <Localized id="configure-auth-piano-sandbox">
              <Label component="legend">Target server</Label>
            </Localized>
            <Localized id="configure-auth-piano-sandbox-details">
              <HelperText>
                Whether to communicate with the production or sandbox API
                endpoints on Piano.
              </HelperText>
            </Localized>
          </FormFieldHeader>
          <OnOffField
            name="auth.integrations.piano.sandbox"
            disabled={disabledInside}
            className={styles.radioButtons}
            onLabel={
              <Localized id="configure-auth-piano-sandbox-on">
                <span>Sandbox</span>
              </Localized>
            }
            offLabel={
              <Localized id="configure-auth-piano-sandbox-off">
                <span>Production</span>
              </Localized>
            }
          />
        </FormField>
        <ClientIDField
          name="auth.integrations.piano.clientID"
          validate={validateWhen(isEnabled, required)}
          disabled={disabledInside}
        />
        <ApiTokenField
          name="auth.integrations.piano.apiToken"
          validate={validateWhen(isEnabled, required)}
          disabled={disabledInside}
        />
        <ClientSecretField
          name="auth.integrations.piano.clientSecret"
          validate={validateWhen(isEnabled, required)}
          disabled={disabledInside}
        />
        <SigningSecretField
          name="auth.integrations.piano.signingSecret"
          validate={validateWhen(isEnabled, required)}
          disabled={disabledInside}
        />
        <TargetFilterField
          label={
            <Localized id="configure-auth-piano-useLoginOn">
              <span>Use Piano login on</span>
            </Localized>
          }
          name="auth.integrations.piano.targetFilter"
          disabled={disabledInside}
        />
        <RegistrationField
          name="auth.integrations.piano.allowRegistration"
          disabled={disabledInside}
        />
      </>
    )}
  </ConfigBoxWithToggleField>
);

export default PianoConfig;
